import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Container,
  Collapse,
  Flex,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { BrochureBenefitCard } from "./BrochureBenefitCard";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import IconLowMonthlySubscription from "../../assets/images/Low Monthly Subscription copy 2.svg";
import IconNoWaitingPeriod from "../../assets/images/No Waiting Period copy 2.svg";
import IconTransparentCoverage from "../../assets/images/Transparent Coverage copy 2.svg";
import IconOilChangeCredit from "../../assets/images/Oil Change Credit.svg";
import IconMoreVehiclesMilesYears from "../../assets/images/More Vehicles, More Miles, More Years copy 2.svg";
import IconDecliningDeductible from "../../assets/images/Declining Deductible.svg";
import IconNoMiddleMan from "../../assets/images/No Middle Man copy 2.svg";
import IconMoreCoverageLessGuesswork from "../../assets/images/More Coverage, Less Guesswork copy 2.svg";
import IconKeepYourRate from "../../assets/images/Keep Your Rate copy 2.svg";
import IconBreakdownAssistance from "../../assets/images/Breakdown Assistance copy 2.svg";
import { Paths } from "../../models/constants/paths";
import { useLocation } from "react-router-dom";

export const BrochureBenefits = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const isMobile = useMediaQuery(
    location.pathname === Paths.Home
      ? "(max-width: 1024px)"
      : "(max-width: 1000px)",
    true,
    {
      getInitialValueInEffect: false,
    }
  );

  const [opened, { toggle }] = useDisclosure(false);

  const cardsData = [
    {
      title: "low-monthly-subscription-cost",
      text: "low-monthly-subscription-cost-description",
      icon: IconLowMonthlySubscription,
    },
    {
      title: "lock-in-rates",
      text: "lock-in-rates-description",
      icon: IconKeepYourRate,
    },
    {
      title: "declining-deductible",
      text: "declining-deductible-description",
      icon: IconDecliningDeductible,
    },
    {
      title: "no-waiting-period",
      text: "no-waiting-period-description",
      icon: IconNoWaitingPeriod,
    },
    {
      title: "oil-change-credits",
      text: "oil-change-credits-description",
      icon: IconOilChangeCredit,
    },
    {
      title: "transparent-coverage",
      text: "transparent-coverage-description",
      icon: IconTransparentCoverage,
    },
    {
      title: "protecting-vehicles",
      text: "protecting-vehicles-description",
      icon: IconMoreVehiclesMilesYears,
    },
    {
      title: "less-red-tape",
      text: "less-red-tape-description",
      icon: IconNoMiddleMan,
    },
    {
      title: "more-coverage-less-guesswork",
      text: "more-coverage-less-guesswork-description",
      icon: IconMoreCoverageLessGuesswork,
    },
    {
      title: "breakdown-assistance",
      text: "breakdown-assistance-description",
      icon: IconBreakdownAssistance,
    },
  ];

  return (
    <>
      <Container size={"responsive"} px={0} bg={"#F8F9FA"} id="coverage_benefits">
        <Stack p={isMobile ? "48px 6%" : "4.8% 9.1% 7.6% 9.1%"} gap={24}>
          <Stack ta={"center"}>
            <Title ff="Crimson Text" fz="40px" lh={"120%"}>
              {t("benefits-title")}
            </Title>
            <Text ff="Montserrat" fz="20px" lh="165%" c="#495057">
              {t("benefits-description")}
            </Text>
          </Stack>
          <Flex gap={isMobile ? "16px" : "24px"} wrap="wrap" justify="center">
            {cardsData.slice(0, 7).map((card, index) => (
              <React.Fragment key={index}>
                <BrochureBenefitCard {...card} />
              </React.Fragment>
            ))}
          </Flex>
          <Collapse in={opened}>
            <Flex gap={isMobile ? "16px" : "24px"} wrap="wrap" justify="center">
              {cardsData.slice(7, cardsData.length).map((card, index) => (
                <React.Fragment key={index}>
                  <BrochureBenefitCard {...card} />
                </React.Fragment>
              ))}
            </Flex>
          </Collapse>
          <Container>
            <Button
              onClick={toggle}
              variant="subtle"
              size="xl"
              mt={0}
              c={"#00C08B"}
            >
              {opened ? "Hide" : "See More"}
            </Button>
          </Container>
        </Stack>
      </Container>
    </>
  );
};
