import logo from "../../assets/images/Christian Brothers Horizontal_Green_RGB-01 (4).svg";
import { useMsal } from "@azure/msal-react";
import { Image, Flex, Divider } from "@mantine/core";
import { SignOutModal } from "../SignOutModal/SignOutModal";

export const LogoHeader = () => {
  const { instance } = useMsal();
  let activeAccount = instance.getActiveAccount();

  function displaySignOutButton() {
    if (activeAccount) {
      return <SignOutModal />;
    } else {
      return null;
    }
  }

  return (
    <>
      <Flex justify="space-between">
        <Flex align={"center"}>
          <Image
            className="headerStyle"
            radius="xs"
            w={"228px"}
            h={"40px"}
            fit="contain"
            src={logo}
          />
        </Flex>
        <div>{displaySignOutButton()}</div>
      </Flex>
      <div className="headerDivider">
        <Divider my="lg" />
      </div>
    </>
  );
};
