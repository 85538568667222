import { ExistingIssues } from "../components/ExistingIssues/ExistingIssues";
import { VehicleFeatures } from "../components/VehicleFeatures/VehicleFeatures";
import { ContinueButton } from "../components/ContinueButton/ContinueButton";
import { StepperComponent } from "../components/Stepper/Stepper";
import { VehicleDetails } from "../components/VehicleDetails/VehicleDetails";
import { PreviousButton } from "../components/PreviousButton/PreviousButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { GlobalContext } from "../context/GlobalContext";
import { Paths } from "../models/constants/paths";
import { React, useContext, useEffect, useState } from "react";
import { Center, Loader } from "@mantine/core";
import { defaultContext } from "../models/constants/defaultContext";
import { PageTitleMetaTags } from "../components/PageTitleMetaTags/PageTitleMetaTags";
import { AzureBlobFiles } from "../models/constants/azureBlobFiles";

export const ConfirmDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { context, setContext } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedVehicle, setSelectedVehicle] = useState();
  const pageTitle = t("browser-tab-titles.confirm-details");
  const pageDescription = t("browser-tab-descriptions.confirm-details");
  const metaTitle = t("browser-meta-titles.confirm-details");
  const metaDescription = t("browser-meta-descriptions.confirm-details");

  useEffect(() => {
    if (!context.completed_pages.confirm_vehicle) {
      setContext(defaultContext);
      navigate(Paths.Home);
    } else {
      setSelectedVehicle(
        context.user.vehicles.find((vehicle) => {
          return vehicle.vin === context.selected_vin;
        })
      );
      setIsLoading(false);
    }
  }, []);

  const handleContinue = () => {
    setContext({
      ...context,
      completed_pages: {
        ...context.completed_pages,
        confirm_details: true,
      },
    });
    navigate(Paths.ConfirmAddress);
  };

  const continueDisabled =
    context.user_answers.problems === null ||
    (context.user_answers.problems &&
      context.user_answers.problems_details === "") ||
    context.user_answers.lift_kit_over_six_inches === null ||
    context.user_answers.aftermarket_enhancements === null;

  return isLoading ? (
    <div className="full-container">
      <div className="content-container">
        <Center>
          <Loader color="#006B5B" size="xl" />
        </Center>
      </div>
    </div>
  ) : (
    <>
      <PageTitleMetaTags
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        metaImage={AzureBlobFiles.MetaImage}
      />
      <StepperComponent activeState={1} />
      <div className="full-container">
        <div className="content-container">
          <PreviousButton path={Paths.ConfirmVehicle} />
          <VehicleDetails vehicle={selectedVehicle} />
          <div className="header">
            <div className="header_2">{t("confirm-vehicle-details")}</div>
          </div>
          <div className="subheader">{t("review-and-update-details")}</div>
          <ExistingIssues />
          <VehicleFeatures vinVerified={true} />
          <ContinueButton
            disabled={continueDisabled}
            label={t("continue")}
            clickAction={() => handleContinue()}
          />
        </div>
      </div>
    </>
  );
};
