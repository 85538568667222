import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Flex, Stack, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { CoverageCard } from "./CoverageCard";
import { NonCoveredComponents } from "../NonCoveredComponents/NonCoveredComponents";
import IconVehicleComponents from "../../assets/images/Most Vehicle Components copy 2.svg";
import IconMakesAndModels from "../../assets/images/Most Makes and Models copy 2.svg";
import IconRoutineMaintenance from "../../assets/images/Routine Maintenance Costs copy 2.svg";
import IconNoDeductibles from "../../assets/images/No Cost Deductibles for Vets, First Responders, and active military copy 2.svg";
import IconKeepYouMoving from "../../assets/images/Things to Keep you Moving copy 2.svg";
import IconCommercialVehicles from "../../assets/images/Commercial Vehicles copy 2.svg";

export const VehicleCoverage = () => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery("(max-width: 1024px)", true, {
		getInitialValueInEffect: false,
	});

	const coveredCardsData = [
		{
			title: "most-vehicle-components",
			text: "most-vehicle-components-description", //update "See sample contract" to link to the sample contract.
			icon: IconVehicleComponents,
		},
		{
			title: "majority-of-makes-and-models",
			text: "majority-of-makes-and-models-description",
			icon: IconMakesAndModels,
		},
		{
			title: "routine-maintenance-costs",
			text: "routine-maintenance-costs-description",
			icon: IconRoutineMaintenance,
		},
		{
			title: "no-cost-deductibles",
			text: "no-cost-deductibles-description",
			icon: IconNoDeductibles,
		},
		{
			title: "keep-you-moving",
			text: "keep-you-moving-description",
			icon: IconKeepYouMoving,
		},
		{
			title: "commercial-vehicles",
			text: "commercial-vehicles-description",
			icon: IconCommercialVehicles,
		},
	];

	return (
		<>
			<Container size={"responsive"} px={0} id="covered">
				<Stack p={isMobile ? "48px 6%" : "6.8% 9.4% 5.3% 9.4%"} gap={24}>
					<Stack ta={"center"}>
						<Title ff="Crimson Text" fz="40px" lh={"120%"}>
							{t("whats-covered")}
						</Title>
						<Text ff="Montserrat" fz="20px" lh="165%" c="#495057">
							{t("whats-covered-description")}
						</Text>
						<Text ff="Montserrat" fz="20px" lh="165%" c="#495057">
							{t("whats-covered-description-2")}
						</Text>
						<Text ff="Montserrat" fz="20px" lh="165%" c="#495057">
							{t("whats-covered-description-3")}
						</Text>
					</Stack>
					<Flex wrap="wrap" justify="center">
						{coveredCardsData.map((card, index) => (
							<React.Fragment key={index}>
								<CoverageCard {...card} isCovered={true} />
							</React.Fragment>
						))}
					</Flex>
				</Stack>
			</Container>
      <NonCoveredComponents />
		</>
	);
};
