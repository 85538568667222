import { IconShieldCheckFilled } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import "./Benefits.scss";
import React from "react";
import {
  Paper,
  Button,
  Container,
  Flex,
  Stack,
  Title,
  Center,
  Modal,
} from "@mantine/core";
import { useState } from "react";
import { BrochureBenefitCard } from "../../components/BrochureBenefits/BrochureBenefitCard";
import { useMediaQuery } from "@mantine/hooks";
import IconLowMonthlySubscription from "../../assets/images/Low Monthly Subscription copy 2.svg";
import IconNoWaitingPeriod from "../../assets/images/No Waiting Period copy 2.svg";
import IconTransparentCoverage from "../../assets/images/Transparent Coverage copy 2.svg";
import IconOilChangeCredit from "../../assets/images/Oil Change Credit.svg";
import IconMoreVehiclesMilesYears from "../../assets/images/More Vehicles, More Miles, More Years copy 2.svg";
import IconDecliningDeductible from "../../assets/images/Declining Deductible.svg";
import IconNoMiddleMan from "../../assets/images/No Middle Man copy 2.svg";
import IconMoreCoverageLessGuesswork from "../../assets/images/More Coverage, Less Guesswork copy 2.svg";
import IconLockInRates from "../../assets/images/Keep Your Rate copy 2.svg";
import IconBreakdownAssistance from "../../assets/images/Breakdown Assistance copy 2.svg"; 

export const Benefits = () => {
  const { t } = useTranslation();
  const benefits = [
    {
      name: t("benefit-one-title"),
      description: t("benefit-one-text"),
    },
    {
      name: t("benefit-two-title"),
      description: t("benefit-two-text"),
    },
    {
      name: t("benefit-three-title"),
      description: t("benefit-three-text"),
    },
  ];

  const cardsData = [
    {
      title: "low-monthly-subscription-cost",
      text: "low-monthly-subscription-cost-description",
      icon: IconLowMonthlySubscription,
    },
    {
      title: "no-waiting-period",
      text: "no-waiting-period-description",
      icon: IconNoWaitingPeriod,
    },
    {
      title: "transparent-coverage",
      text: "transparent-coverage-description",
      icon: IconTransparentCoverage,
    },
    {
      title: "oil-change-credits",
      text: "oil-change-credits-description",
      icon: IconOilChangeCredit,
    },
    {
      title: "protecting-vehicles",
      text: "protecting-vehicles-description",
      icon: IconMoreVehiclesMilesYears,
    },
    {
      title: "declining-deductible",
      text: "declining-deductible-description",
      icon: IconDecliningDeductible,
    },
    {
      title: "less-red-tape",
      text: "less-red-tape-description",
      icon: IconNoMiddleMan,
    },
    {
      title: "more-coverage-less-guesswork",
      text: "more-coverage-less-guesswork-description",
      icon: IconMoreCoverageLessGuesswork,
    },
    {
      title: "lock-in-rates",
      text: "lock-in-rates-description",
      icon: IconLockInRates,
    },
    {
      title: "breakdown-assistance",
      text: "breakdown-assistance-description",
      icon: IconBreakdownAssistance,
    },
  ];

  const styleBenefit = (name, description, index) => {
    return (
      <div className="benefitContainer" key={index}>
        <Flex gap="8px" align="flex-start" direction="row">
          <div style={{ width: "32px" }}>
            <IconShieldCheckFilled
              style={{ color: "#00C08B", alignSelf: "flex-start" }}
            />
          </div>
          <div>
            <div className="benefitName">{name}</div>
            <div className="benefitDescription">{description}</div>
          </div>
        </Flex>
      </div>
    );
  };

  return (
    <Paper shadow="0" radius="md" p="24px" withBorder>
      <div className="benefitsHeader">
        {t("nice-difference-care-plus-includes")}
      </div>
      {benefits.map((benefit, index) => {
        return styleBenefit(benefit.name, benefit.description, index);
      })}
      {/* <Modal
        centered
        opened={opened}
        size={isMobile ? "md" : "75%"}
        onClose={() => setOpened(false)}
      >
        <>
          <Container size={"responsive"} px={0} bg={"#F8F9FA"}>
            <Stack p={isMobile ? "48px 6%" : "4.8% 9.1% 7.6% 9.1%"} gap={24}>
              <Stack ta={"center"}>
                <Title ff="Crimson Text" fz="24px" lh={"120%"}>
                  {t("benefits-title")}
                </Title>
              </Stack>
              <Flex
                gap={isMobile ? "16px" : "24px"}
                wrap="wrap"
                justify="center"
              >
                {cardsData.slice(0, cardsData.length).map((card, index) => (
                  <React.Fragment key={index}>
                    <BrochureBenefitCard {...card} />
                  </React.Fragment>
                ))}
              </Flex>
            </Stack>
          </Container>
        </>
      </Modal>
      <Center>
      <Button
        onClick={() => setOpened(true)}
        variant="subtle"
        size="xl"
        mt={10}
        c={"#006b5b"}
      >
        {opened ? null : t("see-more")}
      </Button>
      </Center> */}
    </Paper>
  );
};
