import { Trans, useTranslation } from "react-i18next";
import React from "react";
import {
  Box,
  Container,
  Image,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useLocation } from "react-router-dom";
import { Paths } from "../../models/constants/paths";
import { boldGreenText } from "../../utils/textStyling";

export const BrochureBenefitCard = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isModal = location.pathname === Paths.Home;

  const isMobile = useMediaQuery(
    isModal ? "(max-width: 1024px)" : "(max-width: 1000px)",
    true,
    {
      getInitialValueInEffect: false,
    }
  );

  const isBrochureModal = () => {
    if (isModal) {
      return (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "64px",
            height: "64px",
            background: "#E5FBF7",
            borderRadius: "4px",
          }}
        >
          <Image src={props.icon} style={{ width: "48px", height: "48px" }} />
        </Box>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Paper
        radius="8px"
        w={isMobile ? "327px" : isModal ? "328px" : "376px"}
        style={{ background: "#F1F3F5" }}
      >
        <Container p="32px">
          <Stack gap="16px">
            {isBrochureModal()}
            <Stack gap="8px">
              <Title
                ff="Montserrat"
                fz={isModal ? "18px" : "20px"}
                fw={600}
                lh="165%"
              >
                {t(props.title)}
              </Title>
              <Text
                ff="Montserrat"
                fz={isModal ? "16px" : "18px"}
                fw={400}
                lh="160%"
              >
                <Trans 
                  i18nKey={props.text}
                  t={t}
                  values={{ boldGreenText }}
                  components={{
                    boldgreen: boldGreenText(props.text),
                  }}
                />
              </Text>
            </Stack>
          </Stack>
        </Container>
      </Paper>
    </>
  );
};
