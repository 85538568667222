import { Paper } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { React, useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { Tooltip } from "../Tooltip/Tooltip";

export const InitialQuote = ({vinVerified}) => {
  const { t } = useTranslation();
  const { context } = useContext(GlobalContext);
  const currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const generateQuote = () => {
    let quote = parseFloat(context.initial_quote.base_rate);
    if (vinVerified) {
      quote += context.surcharges.diesel_surcharge_value;
      quote += context.surcharges.four_by_four_surcharge_value;
      quote += context.surcharges.turbo_surcharge_value;
    }
    return quote;
  }

  return (
    <Paper
      shadow="0"
      radius="md"
      p="16px"
      withBorder
      style={{ marginBottom: "16px", borderWidth: "2px" }}
    >
      <div className="text-box-header">{t("as-low-as")}</div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="header">
          <div className="header_1">
            <div className="header_1_as-low-as">
              {currency.format(generateQuote())}
            </div>
          </div>
        </div>
        <div className="subheader" style={{ display: "flex" }}>
          <div className="subheader_as-low-as">
            &nbsp;&nbsp;{t("per-month")}
          </div>
        </div>
        <div className="header">
          <div className="header_1">
            <div className="header_1_as-low-as" style={{ color: "#636363" }}>
              {vinVerified ? null : '*'}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div className="registration-styling">{t("registration-fee-text")}</div>
        <Tooltip label={t("registration-tooltip")} marginBottom={"16px"} />
      </div>
      {vinVerified ? null : <div className="text-box-subtext">{t("additional-surcharges")}</div>}
    </Paper>
  );
};
