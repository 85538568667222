import IconUnaddressedConditions from "../../assets/images/Unadressed Pre-Existing Conditions copy 2.svg";
import IconCollison from "../../assets/images/Collision copy 2.svg";
import IconAftermarketAlterations from "../../assets/images/Aftermarket Alterations copy 2.svg";
import IconDamagedTitles from "../../assets/images/Damaged Titles copy 2.svg";

export const componentsByCategory = {
  Mechanical: [
    "components.mechanical.shock-absorbers",
    "components.mechanical.struts",
    "components.mechanical.clutch",
    "components.mechanical.brakes",
    "components.mechanical.exhaust-system",
    "components.mechanical.freeze-plugs",
    "components.mechanical.wheels-tires",
    "components.mechanical.hoses",
    "components.mechanical.driving-belts",
    "components.mechanical.filters"
  ],
  Electrical: [
    "components.electrical.batteries",
    "components.electrical.battery-cables",
    "components.electrical.hybrid-electric-vehicle-batteries",
    "components.electrical.relays-fuses",
    "components.electrical.lighting-components",
    "components.electrical.defroster-grid",
    "components.electrical.glow-plugs",
    "components.electrical.spark-plugs"
  ],
  Interior: [
    "components.interior.upholstery-carpet",
    "components.interior.cup-holders-ash-trays",
    "components.interior.trim-moldings",
    "components.interior.dash-pad",
    "components.interior.entertainment-system-components",
    "components.interior.water-leaks-wind-noise",
    "components.interior.seat-components",
  ],
  Exterior: [
    "components.exterior.glass",
    "components.exterior.headlamps",
    "components.exterior.windshield-wiper-components",
    "components.exterior.exterior-ornamentation",
    "components.exterior.bumpers",
    "components.exterior.body-panels-sheet-metal",
    "components.exterior.vinyl-convertible-tops",
    "components.exterior.paint",
    "components.exterior.door-handles-hinges",
  ],
  Safety: ["components.safety.safety-restraint-systems"],
  Miscellaneous: [
    "components.miscellaneous.fasteners",
    "components.miscellaneous.squeaks-rattles",
    "components.miscellaneous.frame-structural-parts",
    "components.miscellaneous.impact-collision-damage",
    "components.miscellaneous.non-oem-components",
    "components.miscellaneous.maintenance-services-components",
    "components.miscellaneous.damage",
  ],
};

export const notCoveredCardsData = [
  {
    title: "unaddressed-pre-existing-conditions",
    text: "unaddressed-pre-existing-conditions-description",
    icon: IconUnaddressedConditions,
  },
  {
    title: "collision",
    text: "collision-description",
    icon: IconCollison,
  },
  {
    title: "aftermarket-alterations",
    text: "aftermarket-alterations-description",
    icon: IconAftermarketAlterations,
  },
  {
    title: "damaged-titles",
    text: "damaged-titles-description",
    icon: IconDamagedTitles,
  },
];

export const desktopStyle = {
  root: {
    width: "600px",
    textAlign: "left",
  },
};

export const mobileStyle = {
  root: {
    width: "375px",
    textAlign: "left",
  },
};