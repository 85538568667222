import { useTranslation } from "react-i18next";
import { Container, Stack, Text, Title } from "@mantine/core";
import { React } from "react";
import { PdfViewer } from "../components/PdfViewer/PdfViewer";
import { PageTitleMetaTags } from "../components/PageTitleMetaTags/PageTitleMetaTags";
import { AzureBlobFiles } from "../models/constants/azureBlobFiles";

export const TermsAndConditions = () => {
  const { t } = useTranslation();
  const pageTitle = t("browser-tab-titles.terms-and-conditions");
  const pageDescription = t("browser-tab-descriptions.terms-and-conditions");
  const metaTitle = t("browser-meta-titles.terms-and-conditions");
  const metaDescription = t("browser-meta-descriptions.terms-and-conditions");

  const pdfURL =
    "https://drive.google.com/viewerng/viewer?embedded=true&url=https://warrantyblob-secondary.z13.web.core.windows.net/M2MVSC%20AmTrust%204.24.24_SAMPLE.pdf";

  return (
    <Container p={0}>
      <PageTitleMetaTags
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        metaImage={AzureBlobFiles.MetaImage}
      />
      <Stack m={"32px 24px 8px 24px"}>
        <Title ff="Crimson Text" fz="40px">
          {t("terms-and-conditions")}
        </Title>
        <Text ff="Montserrat" fz="14px">
          {t("terms-and-conditions-description")}
        </Text>
        <Text ff="Montserrat" fz="20px" fw={600}>
          {t("sample-contract")}
        </Text>
      </Stack>
      <PdfViewer url={AzureBlobFiles.SampleContract} height={"700px"}/>
    </Container>
  );
};
