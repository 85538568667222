import { Popover, Text } from "@mantine/core";
import { React } from "react";
import { useTranslation } from "react-i18next";
import { IconInfoCircle } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";

export const Tooltip = (props) => {
  const [opened, { close, open }] = useDisclosure(false);
  const { t } = useTranslation();

  return (
    <Popover
      position="top"
      width={210}
      style={{ marginBottom: props.marginBottom, marginLeft: "4px" }}
      opened={opened}
    >
      <Popover.Target>
        <IconInfoCircle size={20} onMouseEnter={open} onMouseLeave={close} />
      </Popover.Target>
      <Popover.Dropdown>
        <Text size="xs">{props.label}</Text>
      </Popover.Dropdown>
    </Popover>
  );
};
