import { useTranslation } from "react-i18next";
import { useMediaQuery, useDisclosure } from "@mantine/hooks";
import {
  createTheme,
  AppShell,
  Group,
  Stack,
  Button,
  Drawer,
  Burger,
  Image,
  MantineProvider
} from "@mantine/core";
import IconLogo from "../../assets/images/Christian Brothers Horizontal_Green_RGB-01 (4).svg";
import MobileLogo from "../../assets/images/Legacy-CBA-Logo.svg";
import { SignOutModal } from "../SignOutModal/SignOutModal";

export const BrochureHeader = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 1415px)", true, {
    getInitialValueInEffect: false,
  });
  const useMobileLogo = useMediaQuery("(max-width: 576px)", true, {
    getInitialValueInEffect: false,
  });

  const [navOpened, { open: navOpen, close: navClose }] = useDisclosure(false);

  const theme = createTheme({
    breakpoints: {
      lg: "120em",
    },
  });

  const sectionData = [
    { id: "start_quote", label: t("your-quote") },
    { id: "enrollment_steps", label: t("enrollment-steps") },
    { id: "coverage_benefits", label: t("coverage-benefits") },
    { id: "covered", label: t("whats-covered") },
    { id: "not_covered", label: t("whats-not-covered") },
    { id: "faq", label: t("faqs") },
    { id: "reviews", label: t("reviews") },
  ];

  const scrollToSection = (id) => {
    let offset;
    if (isMobile) {
      offset = 50;
    } else {
      offset = id === "start_quote" ? 90 : 0;
    }
    const section = document.getElementById(id);
    const sectionPosition = section.getBoundingClientRect().top;
    const offsetPosition = sectionPosition + window.scrollY - offset;

    navClose();
    window.scrollTo({ top: offsetPosition, behavior: "smooth" });
  };

  const renderHeaderLeft = () => {
    return isMobile ? (
      <Burger
        opened={navOpened}
        onClick={navOpen}
        size="md"
        color="#F5A800"
        w={"56px"}
      />
    ) : (
      <Image src={IconLogo} w={"228px"} h={"40px"} fit={"contain"}></Image>
    );
  };

  const renderHeaderCenter = () => {
    return isMobile ? (
      useMobileLogo ? (
        <Image src={MobileLogo} w={"150px"} h={"40px"} fit={"contain"}></Image>
      ) : (
        <Image src={IconLogo} w={"228px"} h={"40px"} fit={"contain"}></Image>
      )
    ) : (
      <Group
        justify="flex-start"
        styles={{ root: { fontFamily: "Montserrat", marginRight: "170px" } }}
      >
        {sectionData.map((section, index) => {
          const variant =
            section.id === "start_quote" ? "outline" : "transparent";
          const color = section.id === "start_quote" ? "#ff671d" : "black";
          const width = section.id === "start_quote" ? "110px" : null;
          return (
            <Button
              key={index}
              variant={variant}
              color={color}
              gap="xs"
              size="md"
              styles={{
                root: { paddingLeft: 0, paddingRight: 0, width },
              }}
              onClick={() => scrollToSection(section.id)}
            >
              {section.label}
            </Button>
          );
        })}
      </Group>
    );
  };

  const renderHeaderRight = () => {
    return (
      <SignOutModal />
    );
  };

  const renderPageNavMenu = () => {
    return (
      <Drawer
        opened={navOpened}
        onClose={navClose}
        size="xs"
        withCloseButton={false}
        overlayProps={{ backgroundOpacity: 0, blur: 0 }}
        transitionProps={{ transition: "scale-y" }}
        styles={{
          inner: { marginTop: "83px" },
          content: { background: "#006B5B" },
        }}
      >
        <Stack
          align="flex-start"
          gap={"xs"}
          styles={{ root: { fontFamily: "Montserrat" } }}
        >
          {sectionData.map((section, index) => {
            return (
              <Button
                key={index}
                variant="transparent"
                color="white"
                size="xl"
                onClick={() => scrollToSection(section.id)}
              >
                {section.label}
              </Button>
            );
          })}
        </Stack>
      </Drawer>
    );
  };

  return (
    <MantineProvider theme={theme}>
      <AppShell.Header>
        <Group h="100%" px="20" justify="space-between">
          {renderHeaderLeft()}
          {renderHeaderCenter()}
          {renderHeaderRight()}
        </Group>
      </AppShell.Header>
      {renderPageNavMenu()}
    </MantineProvider>
  );
};
