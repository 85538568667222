import React from "react";
import { useTranslation, Trans } from "react-i18next";
import {
  Accordion,
  Anchor,
  Button,
  Collapse,
  Container,
  List,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { Paths } from "../../models/constants/paths";
import { boldText, boldItalicText } from "../../utils/textStyling";

export const FrequentlyAskedQuestions = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 1024px)", true, {
    getInitialValueInEffect: false,
  });
  const [opened, { toggle }] = useDisclosure(false);

  const sampleContractLink = () => {
    return (
      <Anchor
        underline="hover"
        target="_blank"
        href={Paths.TermsAndConditions}
        ff="Montserrat"
        fz="14px"
      >
        {t("see-sample-contract")}
      </Anchor>
    );
  };

  return (
    <>
      <Container size={"responsive"} px={0} bg={"#F8F9FA"} id="faq">
        <Stack
          p={isMobile ? "48px 24px" : "4.4% 3.3% 4.4% 3.3%"}
          gap={isMobile ? "32px" : "56px"}
          align="center"
        >
          <Container size={"responsive"} px={0}>
            <Title ff="Crimson Text" fz="40px">
              {t("frequently-asked-questions")}
            </Title>
          </Container>
          <Accordion
            variant="contained"
            multiple
            w={isMobile ? "100%" : "46%"}
            bg={"white"}
          >
            <Accordion.Item
              ff={"Montserrat"}
              fz={16}
              fw={400}
              lh={"155%"}
              value="question1"
            >
              <Accordion.Control py={4}>
                {t("faq.1.question")}
              </Accordion.Control>
              <Accordion.Panel>
                <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  {t("faq.1.text-1")}
                </Text>
                <br />
                <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  {t("faq.1.text-2")}
                </Text>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item
              ff={"Montserrat"}
              fz={16}
              fw={400}
              lh={"155%"}
              value="question2"
            >
              <Accordion.Control py={4}>
                {t("faq.2.question")}
              </Accordion.Control>
              <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  {t("faq.2.text-1")}
                </Text>
                <br />
                <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  {t("faq.2.text-2")}
                </Text>
                <br />
                <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  {t("faq.2.text-3")}
                </Text>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item
              ff={"Montserrat"}
              fz={16}
              fw={400}
              lh={"155%"}
              value="question3"
            >
              <Accordion.Control py={4}>
                {t("faq.3.question")}{" "}
              </Accordion.Control>
              <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  {t("faq.3.text-1")}
                </Text>
                <br />
                <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  {t("faq.3.text-2")}
                </Text>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item
              ff={"Montserrat"}
              fz={16}
              fw={400}
              lh={"155%"}
              value="question4"
            >
              <Accordion.Control py={4}>
                {t("faq.4.question")}{" "}
              </Accordion.Control>
              <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  {t("faq.4.text-1")}
                </Text>
                <br />
                <List>
                  <List.Item>{t("faq.4.text-2")}</List.Item>
                  <br />
                  <List.Item>{t("faq.4.text-3")}</List.Item>
                  <br />
                  <List.Item>{t("faq.4.text-4")}</List.Item>
                  <br />
                  <List.Item>{t("faq.4.text-5")}</List.Item>
                  <br />
                  <List.Item>{t("faq.4.text-6")}</List.Item>
                  <br />
                  <List.Item>{t("faq.4.text-7")}</List.Item>
                  <br />
                  <List.Item>{t("faq.4.text-8")}</List.Item>
                  <br />
                  <List.Item>{t("faq.4.text-9")}</List.Item>
                  <br />
                  <List.Item>{t("faq.4.text-10")}</List.Item>
                  <br />
                  <List.Item>{t("faq.4.text-11")}</List.Item>
                  <br />
                  <List.Item>{t("faq.4.text-12")}</List.Item>
                  <br />
                  <List.Item>
                    {t("faq.4.text-13")}
                    <List withPadding>
                      <List.Item>{t("faq.4.text-14")}</List.Item>
                      <List.Item>{t("faq.4.text-15")}</List.Item>
                      <List.Item>{t("faq.4.text-16")}</List.Item>
                    </List>
                  </List.Item>
                </List>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item
              ff={"Montserrat"}
              fz={16}
              fw={400}
              lh={"155%"}
              value="question5"
            >
              <Accordion.Control py={4}>
                {t("faq.5.question")}{" "}
              </Accordion.Control>
              <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  {t("faq.5.text-1")}
                </Text>
                <br />
                <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  <Trans
                    i18nKey={"faq.5.text-2"}
                    t={t}
                    values={{ sampleContractLink }}
                    components={{ contractLink: sampleContractLink() }}
                  />
                </Text>
              </Accordion.Panel>
            </Accordion.Item>
            <Collapse in={opened}>
              <Accordion.Item
                ff={"Montserrat"}
                fz={16}
                fw={400}
                lh={"155%"}
                value="question6"
              >
                <Accordion.Control py={4}>
                  {t("faq.6.question")}
                </Accordion.Control>
                <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.6.text-1")}
                  </Text>
                  <br />
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.6.text-2")}
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item
                ff={"Montserrat"}
                fz={16}
                fw={400}
                lh={"155%"}
                value="question7"
              >
                <Accordion.Control py={4}>
                  {t("faq.7.question")}
                </Accordion.Control>
                <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.7.text-1")}
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item
                ff={"Montserrat"}
                fz={16}
                fw={400}
                lh={"155%"}
                value="question8"
              >
                <Accordion.Control py={4}>
                  {t("faq.8.question")}
                </Accordion.Control>
                <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.8.text-1")}
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item
                ff={"Montserrat"}
                fz={16}
                fw={400}
                lh={"155%"}
                value="question9"
              >
                <Accordion.Control py={4}>
                  {t("faq.9.question")}
                </Accordion.Control>
                <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.9.text-1")}
                  </Text>
                  <br />
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.9.text-2")}
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item
                ff={"Montserrat"}
                fz={16}
                fw={400}
                lh={"155%"}
                value="question10"
              >
                <Accordion.Control py={4}>
                  {t("faq.10.question")}
                </Accordion.Control>
                <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.10.text-1")}
                  </Text>
                  <br />
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.10.text-2")}
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item
                ff={"Montserrat"}
                fz={16}
                fw={400}
                lh={"155%"}
                value="question11"
              >
                <Accordion.Control py={4}>
                  {t("faq.11.question")}
                </Accordion.Control>
                <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.11.text-1")}
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item
                ff={"Montserrat"}
                fz={16}
                fw={400}
                lh={"155%"}
                value="question12"
              >
                <Accordion.Control py={4}>
                  {t("faq.12.question")}
                </Accordion.Control>
                <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  <Trans
                    i18nKey={"faq.12.text-1"}
                    t={t}
                    values={{ sampleContractLink }}
                    components={{ contractLink: sampleContractLink() }}
                  />
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item
                ff={"Montserrat"}
                fz={16}
                fw={400}
                lh={"155%"}
                value="question13"
              >
                <Accordion.Control py={4}>
                  {t("faq.13.question")}
                </Accordion.Control>
                <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.13.text-1")}
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item
                ff={"Montserrat"}
                fz={16}
                fw={400}
                lh={"155%"}
                value="question14"
              >
                <Accordion.Control py={4}>
                  {t("faq.14.question")}
                </Accordion.Control>
                <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.14.text-1")}
                  </Text>
                  <br />
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.14.text-2")}
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item
                ff={"Montserrat"}
                fz={16}
                fw={400}
                lh={"155%"}
                value="question15"
              >
                <Accordion.Control py={4}>
                  {t("faq.15.question")}
                </Accordion.Control>
                <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.15.text-1")}
                  </Text>
                  <br />
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.15.text-2")}
                    <br />
                    {t("faq.15.text-3")}
                    <br />
                    {t("faq.15.text-4")}
                    <br />
                    {t("faq.15.text-5")}
                    <br />
                    {t("faq.15.text-6")}
                  </Text>
                  <br />
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.15.text-7")}
                  </Text>
                  <br />
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.15.text-8")}
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item
                ff={"Montserrat"}
                fz={16}
                fw={400}
                lh={"155%"}
                value="question16"
              >
                <Accordion.Control py={4}>
                  {t("faq.16.question")}
                </Accordion.Control>
                <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.16.text-1")}
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item
                ff={"Montserrat"}
                fz={16}
                fw={400}
                lh={"155%"}
                value="question17"
              >
                <Accordion.Control py={4}>
                  {t("faq.17.question")}
                </Accordion.Control>
                <Accordion.Panel fz={"12.5pt"} fw={400} lh={"145%"}>
                  <Trans
                    i18nKey={"faq.17.text-1"}
                    t={t}
                    values={{ boldText }}
                    components={{
                      bold: boldText("faq.17.text-1"),
                    }}
                  />
                  <br />
                  <br />
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.17.text-2")}
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item
                ff={"Montserrat"}
                fz={16}
                fw={400}
                lh={"155%"}
                value="question18"
              >
                <Accordion.Control py={4}>
                  {t("faq.18.question")}
                </Accordion.Control>
                <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.18.text-1")}
                  </Text>
                  <br />
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.18.text-2")}
                  </Text>
                  <br />
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.18.text-3")}
                    <Anchor
                      ff={"Montserrat"}
                      fz={14}
                      fw={400}
                      lh={"145%"}
                      href="https://www.cbac.com/about-us/"
                    >
                      {" "}
                      {t("click")}
                    </Anchor>
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item
                ff={"Montserrat"}
                fz={16}
                fw={400}
                lh={"155%"}
                value="question19"
              >
                <Accordion.Control py={4}>
                  {t("faq.19.question")}
                </Accordion.Control>
                <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.19.text-1")}
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item
                ff={"Montserrat"}
                fz={16}
                fw={400}
                lh={"155%"}
                value="question20"
              >
                <Accordion.Control py={4}>
                  {t("faq.20.question")}
                </Accordion.Control>
                <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.20.text-1")}
                  </Text>
                  <br />
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.20.text-2")}
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item
                ff={"Montserrat"}
                fz={16}
                fw={400}
                lh={"155%"}
                value="question21"
              >
                <Accordion.Control py={4}>
                  {t("faq.21.question")}
                </Accordion.Control>
                <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.21.text-1")}
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item
                ff={"Montserrat"}
                fz={16}
                fw={400}
                lh={"155%"}
                value="question22"
              >
                <Accordion.Control py={4}>
                  {t("faq.22.question")}
                </Accordion.Control>
                <Accordion.Panel ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                  <Text ff={"Montserrat"} fz={14} fw={400} lh={"145%"}>
                    {t("faq.22.text-1")}
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
            </Collapse>
          </Accordion>
          <Container>
            <Button
              onClick={toggle}
              variant="subtle"
              size="xl"
              mt={0}
              c={"#00C08B"}
            >
              {opened ? "Hide" : "See More"}
            </Button>
          </Container>
        </Stack>
      </Container>
    </>
  );
};
