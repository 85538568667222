import { React, useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Center, Loader, Text, Stack } from "@mantine/core";
import { GlobalContext } from "../../context/GlobalContext";

export const PdfViewer = (props) => {
	const { t } = useTranslation();
  const iframeRef = useRef(null);
  const interval = useRef();
  const { context } = useContext(GlobalContext);

  const [loaded, setLoaded] = useState(false);
  const [dviLoadFailed, setDviLoadFailed] = useState(false);

  const onIframeLoaded = () => {
    setLoaded(true);
  };

  const showSpinner = () => {
    return (loaded || dviLoadFailed) ? null : (
      <div className="full-container">
        <div className="content-container">
          <Center>
            <Loader color="#006B5B" size="xl" />
          </Center>
        </div>
      </div>
    );
  };

  const showDviOrDownloadPrompt = () => {
    return (dviLoadFailed) ? 
    (
      <div className="full-container">
        <div className="content-container">
          <Center>
            <Stack>
              <Center>
                <Text ff="Crimson Text" fz="24px" >
                  {t("dvi-download-prompt")}
                </Text>
              </Center>
              <Center>
                <Button
                  styles={{
                    root: {
                      fontFamily: "Montserrat",
                      background: "#006B5B",
                      width: "160px",
                    },
                  }}
                  onClick={() => {
                    window.open(context.vehicle_data.dvi_url);
                  }}
                >
                  {t("download-pdf")}
                </Button>
              </Center>
            </Stack>
          </Center>
        </div>
      </div>
    ) : (
      <iframe
        ref={iframeRef}
        onLoad={onIframeLoaded}
        src={props.url}
        width="100%"
        height={props.height}
      />
    );
  };

  useEffect(() => {
    iframeRef.current.src = props.url;
    setTimeout(() => {
      try {
        if (iframeRef.current.contentWindow.document.body.innerHTML === "") {
          setDviLoadFailed(true);
        }
      } catch (e) {
        onIframeLoaded();
      }
    }, 7000);
  }, []);

  return (
    <>
      {showSpinner()}
      {showDviOrDownloadPrompt()}
    </>
  );
};
