import { Select, NumberInput, Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { React, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { GlobalContext } from "../../context/GlobalContext";
import { Paths } from "../../models/constants/paths";
import { Group, Stack } from "@mantine/core";
import axios from "axios";
import { SchedulerEndpoints } from "../../models/constants/schedulerEndpoints";
import { defaultContext } from "../../models/constants/defaultContext";

export const YMMMForm = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { context, setContext } = useContext(GlobalContext);

  const [year, setYear] = useState("");
  const [yearList, setYearList] = useState([]);
  const [make, setMake] = useState("");
  const [makeList, setMakeList] = useState([]);
  const [model, setModel] = useState("");
  const [modelList, setModelList] = useState([]);
  const [mileage, setMileage] = useState("");

  const ymmmForm = useForm({
    initialValues: {
      year: null || context.quote_form_data.year || props.year,
      make: null || context.quote_form_data.make || props.make,
      model: null || context.quote_form_data.model || props.model,
      mileage: null || context.quote_form_data.mileage || props.mileage,
    },
    validate: {
      year: (value) => (!value ? t("vehicle-year-required") : null),
      make: (value) => (!value ? t("vehicle-make-required") : null),
      model: (value) => (!value ? t("vehicle-model-required") : null),
      mileage: (value) => (!value ? t("vehicle-mileage-required") : null),
    },
  });

  const getYears = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_YMM_API_URL + SchedulerEndpoints.Years
      );
      if (response?.status === 200) {
        return response;
      }
    } catch (error) {
      navigate(Paths.Error);
    }
  };

  const getMakes = async (year) => {
    try {
      const response = year
        ? await axios.get(
            process.env.REACT_APP_YMM_API_URL + SchedulerEndpoints.Makes,
            {
              params: {
                year: year,
              },
            }
          )
        : null;
      if (response?.status === 200) {
        return response;
      }
    } catch (error) {
      navigate(Paths.Error);
    }
  };

  const getModels = async (year, make) => {
    try {
      const response =
        year && make
          ? await axios.get(
              process.env.REACT_APP_YMM_API_URL + SchedulerEndpoints.Models,
              {
                params: {
                  year: year,
                  make: make,
                },
              }
            )
          : null;
      if (response?.status === 200) {
        return response;
      }
    } catch (error) {
      navigate(Paths.Error);
    }
  };

  const autoFillYMMMForm = (year, make, model, mileage) => {
    setYear(year);
    getMakes(year)
      .then((res) => {
        if (res) {
          setMakeList(res?.data?.data);
          ymmmForm.setFieldValue("make", make);
          setMake(make);
          getModels(year, make).then((res) => {
            if (res) {
              setModelList(res?.data?.data);
              ymmmForm.setFieldValue("model", model);
              setModel(model);
              ymmmForm.setFieldValue("mileage", mileage);
              setMileage(mileage);
            }
          });
        }
      })
      .catch(() => {
        navigate(Paths.Error);
      });
  };

  const submitForm = (formData) => {
    if (ymmmForm.isValid()) {
      setContext({
        ...context,
        vehicle_data: formData,
        quote_form_data: {
          ...context.quote_form_data,
          year: formData.year,
          make: formData.make,
          model: formData.model,
          mileage: formData.mileage, 
        },
        completed_pages: { ...context.completed_pages, home: true },
      });
      navigate(Paths.Questionnaire);
    }
  }

  useEffect(() => {
    getYears()
      .then((res) => {
        setYearList(res?.data?.data);
      })
      .catch(() => {
        navigate(Paths.Error);
      });
    if (
      context.quote_form_data.year &&
      context.quote_form_data.make &&
      context.quote_form_data.model &&
      context.quote_form_data.mileage
    ) {
      autoFillYMMMForm(
        context.quote_form_data.year,
        context.quote_form_data.make,
        context.quote_form_data.model,
        context.quote_form_data.mileage
      );
    }
  }, []);

  useEffect(() => {
    if (
      ymmmForm.values.year != context.quote_form_data.year
    ) {
      setContext({
        ...defaultContext,
        quote_form_data: context.quote_form_data,
      });
    }
    setYear(ymmmForm.values.year);
    getMakes(ymmmForm.values.year)
      .then((res) => {
        if (res) {
          setMakeList(res?.data?.data);
        }
      })
      .catch(() => {
        navigate(Paths.Error);
      });
    ymmmForm.setFieldValue("make", null);
    ymmmForm.setFieldValue("model", null);
  }, [ymmmForm.values.year]);

  useEffect(() => {
    if (
      ymmmForm.values.make &&
      ymmmForm.values.make != context.quote_form_data.make
    ) {
      setContext({
        ...defaultContext,
        quote_form_data: context.quote_form_data,
      });
    }
    setMake(ymmmForm.values.make);
    getModels(ymmmForm.values.year, ymmmForm.values.make)
      .then((res) => {
        if (res) {
          setModelList(res?.data?.data);
        }
      })
      .catch(() => {
        navigate(Paths.Error);
      });
    ymmmForm.setFieldValue("model", null);
  }, [ymmmForm.values.make]);

  useEffect(() => {
    if (
      ymmmForm.values.model &&
      ymmmForm.values.model != context.quote_form_data.model
    ) {
      setContext({
        ...defaultContext,
        quote_form_data: context.quote_form_data,
      });
    }
    setModel(ymmmForm.values.model);
  }, [ymmmForm.values.model]);

  useEffect(() => {
    if (
      ymmmForm.values.mileage &&
      ymmmForm.values.mileage !== context.vehicle_data.mileage
    ) {
      setContext({
        ...defaultContext,
        quote_form_data: context.quote_form_data,
      });
    }
    setMileage(ymmmForm.values.mileage);
  }, [ymmmForm.values.mileage]);

  useEffect(() => {
    if(props.make && props.model && props.year && props.mileage) {
      setContext({
        ...context,
        quote_form_data: {
          make: props.make,
          model: props.model,
          year: props.year,
          mileage: props.mileage
        }
      });
    }
  }, [])

  // Mantine Select does not offer out of the box functionality to limit
  // user input in the dropdown, so this function listens to the keys
  // pressed in the year input and disallows characters other than numbers
  // and more than 4 characters
  function limitInput(event) {
    const acceptableKeys = [
      8,
      9,
      46,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      96,
      97,
      98,
      99,
      100,
      101,
      102,
      103,
      104,
      105,
    ];
    if (!acceptableKeys.includes(event.which)) {
      event.preventDefault();
    }
    if (
      document.getElementById("initial-quote-year").value.toString().length > 3
    ) {
      if (event.which !== 8 && event.which !== 9 && event.which !== 46) {
        event.preventDefault();
      }
    }
  }

  return (
    <form
      id="ymmmForm"
      onSubmit={ymmmForm.onSubmit((values) => submitForm(values))}
    >
      <Stack gap={16} ff={"Montserrat"} fz={16} fw={600} lh={"155%"}>
        <Group gap={16} grow>
          <Select
            id="initial-quote-year"
            label={t("year")}
            onKeyDown={(event) => limitInput(event)}
            placeholder={t("year")}
            size="lg"
            data={yearList}
            value={year}
            searchable
            withCheckIcon={false}
            styles={{
              dropdown: {
                fontFamily: "Montserrat",
                fontSize: "16px",
              },
            }}
            {...ymmmForm.getInputProps("year")}
          />
          <NumberInput
            label={t("mileage")}
            placeholder={t("mileage")}
            maxLength={7}
            allowDecimal={false}
            allowNegative={false}
            allowLeadingZeros={false}
            thousandSeparator=","
            hideControls
            size="lg"
            value={mileage}
            {...ymmmForm.getInputProps("mileage")}
          />
        </Group>
        <Select
          label={t("make")}
          placeholder={t("select-vehicle-make")}
          size="lg"
          data={makeList}
          value={make}
          disabled={!ymmmForm.getInputProps("year").value}
          clearable
          searchable
          withCheckIcon={false}
          styles={{
            dropdown: {
              fontFamily: "Montserrat",
              fontSize: "16px",
            },
          }}
          {...ymmmForm.getInputProps("make")}
        />
        <Select
          label={t("model")}
          placeholder={t("select-vehicle-model")}
          size="lg"
          data={modelList}
          value={model}
          disabled={!ymmmForm.getInputProps("make").value}
          clearable
          searchable
          withCheckIcon={false}
          styles={{
            dropdown: {
              fontFamily: "Montserrat",
              fontSize: "16px",
            },
          }}
          {...ymmmForm.getInputProps("model")}
        />
      </Stack>
      <Button type="submit" fullWidth color="#FF671D" className="button">
        {t("get-your-quote")}
      </Button>
    </form>
  );
};
