import { Stepper, rem } from '@mantine/core';
import { IconListCheck, IconHeartHandshake, IconCar, IconCheck } from '@tabler/icons-react';
import { React, useState} from 'react';

//use stepper one and insert icons and remove styling in order to get achieved look.
export const StepperComponent = (activeState) => {
  const [active] = useState(activeState); //Stepper component ingest page# and use page name to determine style
  return (
      <Stepper 
        active={active.activeState}
        allowNextStepsSelect={false}
        completedIcon={<IconCheck 
          style={{ width: rem(18), height: rem(18) }}
          color={('#ffffff')}
          />}
        color={('#006b5b')}
      >
        <Stepper.Step icon={<IconCar style={{ width: rem(18), height: rem(18) }} />} />
        <Stepper.Step icon={<IconListCheck style={{ width: rem(18), height: rem(18), color:'#000000'}} />} />
        <Stepper.Step icon={<IconHeartHandshake style={{ width: rem(18), height: rem(18), color:'#000000' }} />} />
      </Stepper>
  );
};