import { React } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Image, Paper, Stack, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

export const HowItWorksCard = (props) => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery("(max-width: 1024px)", true, {
		getInitialValueInEffect: false,
	});

	return (
		<>
			<Paper radius="4px" withBorder style={{ border: "1px solid #006b5b" }}>
				<Flex direction={isMobile ? "column" : "row"}>
					<Image src={props.image} mih={269} miw={269}></Image>
					<Stack p="32px">
						<Flex w="40px" h="40px" bg="#004c3d" radius="4px" justify="center" align="center">
							<Image src={props.icon} style={{ width: "32px", height: "32px" }} />
						</Flex>
						<Stack gap="4px">
							<Title order={3} ff="Crimson Text" fz={isMobile ? "24px" : "32px"} fw="600" lh="32px">
								{t(props.title)}
							</Title>
							<Text ff="Montserrat" fz={isMobile ? "16px" : "18px"} lh="155%" c="#495057">
								{t(props.text)}
							</Text>
						</Stack>
					</Stack>
				</Flex>
			</Paper>
		</>
	);
};
