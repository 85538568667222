import { React, useContext, useEffect } from "react";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { StepperComponent } from "../components/Stepper/Stepper";
import { Container } from "react-bootstrap";
import { IdTokenData } from "../components/DataDisplay/DataDisplay";
import { AgreementsForm } from "../components/AgreementsForm/AgreementsForm";
import { GlobalContext } from "../context/GlobalContext";
import { defaultContext } from "../models/constants/defaultContext";
import { useNavigate } from "react-router-dom";
import { Paths } from "../models/constants/paths";
import { useTranslation } from "react-i18next";
import { PageTitleMetaTags } from "../components/PageTitleMetaTags/PageTitleMetaTags";
import { AzureBlobFiles } from "../models/constants/azureBlobFiles";

export const Agreements = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const navigate = useNavigate();
  const { context, setContext } = useContext(GlobalContext);
  const { t } = useTranslation();
  const pageTitle = t("browser-tab-titles.agreements");
  const pageDescription = t("browser-tab-descriptions.agreements");
  const metaTitle = t("browser-meta-titles.agreements");
  const metaDescription = t("browser-meta-descriptions.agreements");
  
  useEffect(() => {
    // Reroute to home page if
    // 1. No local storage context AND Final Quote
    //    page is not completed in context
    // 2. Final Quote page is not completed in local storage AND
    //    Final Quote page is not completed in context
    console.log(context.final_quote);
    if (
      (!localStorage.getItem("context") ||
        !JSON.parse(localStorage.getItem("context")).completed_pages
          .final_quote) &&
      !context.completed_pages.final_quote
    ) {
      setContext(defaultContext);
      navigate(Paths.Home);
    }
  }, []);

  return (
    <>
      <PageTitleMetaTags
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        metaImage={AzureBlobFiles.MetaImage}
      />
      <AuthenticatedTemplate>
        {activeAccount ? (
          <Container>
            <IdTokenData idTokenClaims={activeAccount.idTokenClaims} />
            <StepperComponent activeState={2} />
            <AgreementsForm />
          </Container>
        ) : null}
      </AuthenticatedTemplate>
    </>
  );
};
