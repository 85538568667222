import {
  SegmentedControl,
  Container,
  Stack,
  Flex,
  Title,
  Text,
  Select,
  Button,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { React, useState } from "react";
import { ComponentsList } from "../ComponentsList/ComponentsList";
import { notCoveredCardsData } from "../../models/constants/nonCoveredComponents";
import { CoverageCard } from "../VehicleCoverage/CoverageCard";

export const NonCoveredComponents = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 1024px)", true, {
    getInitialValueInEffect: false,
  });
  const [selectedCategory, setSelectedCategory] = useState(t("mechanical"));

  const handleScrollToTop = () => {
    const section = document.getElementById("start_quote");
    const offset = 10;
    const sectionPosition = section.getBoundingClientRect().top;
    const offsetPosition = sectionPosition + window.scrollY - offset;

    window.scrollTo({top: offsetPosition, behavior: "smooth"});
  };

  const categories = [
    t("mechanical"),
    t("electrical"),
    t("interior"),
    t("exterior"),
    t("safety"),
    t("miscellaneous"),
  ];

  return (
    <Container size={"responsive"} px={0} bg={"#F8F9FA"} id="not_covered">
      <Stack
        p={isMobile ? "48px 6%" : "6.8% 9.4% 5.3% 9.4%"}
        styles={{ root: { width: "100%" } }}
      >
        <Stack ta={"center"}>
          <Title ff="Crimson Text" fz="40px" lh={"120%"}>
            {t("whats-not-covered")}
          </Title>
          <Text ff="Montserrat" fz="20px" lh="165%" c="#495057">
            {t("whats-not-covered-description")}
          </Text>
          <Title ff="Crimson Text" fz="32px" lh={"120%"} mt="40px">
            {t("vehicle-components-not-covered")}
          </Title>
          <Flex
            justify="center"
            align={isMobile ? "center" : "flex-start"}
            direction={isMobile ? "column" : "row"}
            gap="xl"
            styles={{
              root: {
                marginTop: "32px",
              },
            }}
          >
            {isMobile ? (
              <Select
                data={categories}
                allowDeselect={false}
                withCheckIcon={false}
                size="lg"
                defaultValue={t("mechanical")}
                value={selectedCategory}
                onChange={setSelectedCategory}
                styles={{
                  root: {
                    width: "375px",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontWeight: "600",
                  },
                  dropdown: {
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                  },
                }}
              />
            ) : (
              <SegmentedControl
                orientation="vertical"
                size={isMobile ? "sm" : "xl"}
                data={categories}
                value={selectedCategory}
                defaultValue={t("mechanical")}
                onChange={setSelectedCategory}
                styles={{
                  root: {
                    width: "300px",
                    background: "#fff",
                  },
                  label: {
                    fontFamily: "Montserrat",
                    fontSize: "20px",
                    lineHeight: "125%",
                    color: "#000",
                    fontStyle: "normal",
                    fontWeight: "600",
                  },
                  indicator: {
                    background: "rgba(229, 251, 247, 1)",
                  },
                }}
              />
            )}
            <ComponentsList category={selectedCategory} />
          </Flex>
        </Stack>
      </Stack>
      <Stack p={isMobile ? "48px 6%" : "4.4% 22.8%"} gap={24}>
        <Stack ta="center">
          <Title ff="Crimson Text" fz="32px" lh={"120%"}>
            {t("other-coverage-exceptions")}
          </Title>
        </Stack>
        <Flex wrap="wrap" justify="center" gap={isMobile ? 0 : 20} mt="24px">
          {notCoveredCardsData.map((card, index) => (
            <CoverageCard key={index} {...card} isCovered={true} />
          ))}
        </Flex>
        {isMobile ? (
          <Button
            onClick={handleScrollToTop}
            radius={4}
            fullWidth
            color="#FF671D"
            className="button"
          >
            {t("your-quote")}
          </Button>
        ) : (
          <Container size={"responsive"}>
            <Button
              onClick={handleScrollToTop}
              radius={4}
              size="md"
              color="#FF671D"
              className="button"
            >
              {t("your-quote")}
            </Button>
          </Container>
        )}
      </Stack>
    </Container>
  );
};
