import { Stack, Paper } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { componentsByCategory, desktopStyle, mobileStyle } from "../../models/constants/nonCoveredComponents";

export const ComponentsList = (props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 1024px)", true, {
    getInitialValueInEffect: false,
  });

  return (
    <Stack gap="0">
      <Paper
        shadow="0"
        radius="0"
        p="lg"
        ff="Montserrat"
        fz="20px"
        fw="600"
        styles={{
          root: {
            background: "rgba(229, 251, 247, 1)",
            color: "black",
            textAlign: "left",
          },
        }}
      >
        {t("components-not-covered")}
      </Paper>
      {componentsByCategory[props.category].map((category, index) => {
        return (
          <Paper
            shadow="0"
            withBorder
            radius="0"
            p="lg"
            ff="Montserrat"
            fz="20px"
            styles={isMobile ? mobileStyle : desktopStyle}
            key={index}
          >
            {t(category)}
          </Paper>
        );
      })}
    </Stack>
  );
};
